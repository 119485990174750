import { render, staticRenderFns } from "./HoroscopeComponent.vue?vue&type=template&id=bdabde80&scoped=true"
import script from "./HoroscopeComponent.vue?vue&type=script&lang=js"
export * from "./HoroscopeComponent.vue?vue&type=script&lang=js"
import style0 from "./HoroscopeComponent.vue?vue&type=style&index=0&id=bdabde80&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdabde80",
  null
  
)

export default component.exports